import React from 'react';
import Loader from "./Loader";

import './formFields.scss';

export const GridButton = props => {
	const baseClassName = "gridButton";
	const className = props.disabled ? `${baseClassName}Disabled` : props.selected ? `${baseClassName}Selected` : baseClassName;

	const style = props.style || {};

	return (
		<div style={style}
		     className={className}
		     onClick={props.working ? null : props.onClick}>
			{ props.leftIcon && props.leftIcon }
			{ props.working ? <Loader /> : props.children }
			{ props.rightIcon && props.rightIcon }
		</div>
	)
};

export const ToolbarButton = props => {
	const baseClassName = `toolbarButton${props.dark ? 'Dark' : props.light ? 'Light' : ''}`;
	const className = props.disabled ? `${baseClassName}Disabled` : baseClassName;

	const style = props.style || {};

	return (
		<div style={style}
		     className={className}
		     onClick={props.working ? null : props.onClick}>
			{ props.leftIcon && props.leftIcon }
			{ props.working ? <Loader /> : props.children }
			{ props.rightIcon && props.rightIcon }
		</div>
	)
};

export const LinkButton = props => {
	const baseClassName = "linkButton";
	const className = props.disabled ? `${baseClassName}Disabled` : baseClassName;

	const style = props.style || {};

	return (
		<div style={style}
		     className={className}
		     onClick={props.working ? null : props.onClick}>
			{ props.leftIcon && props.leftIcon }
			{ props.leftIcon && <span>  </span> }
			{ props.working ? <Loader /> : props.children }
			{ props.rightIcon && props.rightIcon }
		</div>
	)
};

export const Input = props => {
	const baseClassName = props.rows ? 'textAreaField' : 'inputField';
	const className = props.disabled ? `${baseClassName}Disabled` : baseClassName;

	if(props.rows) {
		return (
			<textarea {...props}
			          ref={props.innerref}
			          className={className}>
				{props.children}
				</textarea>
		)
	}

	return (
		<input
			{...props}
			type="text"
			ref={props.innerref}
			className={className} />
	)
};

export const Select = props => {
	const baseClassName = 'selectField';
	const className = props.disabled ? `${baseClassName}Disabled` : baseClassName;

	return (
		<select
			{...props}
			ref={props.innerref}
			className={className}>
			{ props.children }
		</select>
	)
};
