import React, {useEffect, useState} from 'react';
import * as schoolActions from "../../redux/School/actions";
import {useDispatch, useSelector} from "react-redux";
import FilterRow from './FilterRow';
import PageBase from "../../components/PageBase";
import EditTeacher from "./EditTeacher";
import CustomDataTable from "../../components/CustomDataTable";
import PopupWin from "../../components/PopupWin";
import Toaster from "../../components/Toaster";

import './teachers.scss';

const log = require("loglevel").getLogger("AdminUsers");
log.setLevel("debug");

const Teachers = props => {
	const [loading, setLoading] = useState(true);
	const [clearSelectedRows, setClearSelectedRows] = useState(false);
	const [editingTeacher, setEditingTeacher] = useState(null);
	const [selectedFilter, setSelectedFilter] = useState('');
	const [selectedRows, setSelectedRow] = useState([]);
	const teachers = useSelector(state => state.school.teachers);
	const selectedSchool = useSelector(state => state.school.selectedSchool);

	const columns = [
		{
			id: 'teacher_id',
			name: 'מספר סידורי',
			selector: row => row.teacher_id,
			cell: row => <div >{row.teacher_id}</div>,
			width: '180px'
		}, {
			id: 'teacher_name',
			name: 'שם המורה',
			selector: row => row.teacher_name,
			cell: row => <div>{row.teacher_name}</div>,
			width: '200px'
		}, {
			id: 'teacher_phone',
			name: 'מספר טלפון',
			selector: row => row.teacher_phone,
			cell: row => <div style={{direction: 'ltr'}}>{row.teacher_phone}</div>,
			width: '200px'
		}, {
			id: 'class_name',
			name: 'כיתה',
			selector: row => row.class_name,
			cell: row => <div style={{direction: 'ltr'}}>{row.class_name}</div>,
			width: '200px'
		}
	];

	const pageTitle = selectedSchool ? `מורי בתי הספר ${selectedSchool.school_name}` : '';

	const dispatch = useDispatch();

	useEffect(() => {
		const Load = async () => {
			setLoading(true);
			await dispatch(schoolActions.getTeachers(selectedSchool.school_id));
			setLoading(false);
		};
		Load();
	}, [dispatch, selectedSchool]);

	const onAfterUpdate = async () => {
		setEditingTeacher(null);
		await dispatch(schoolActions.getTeachers(selectedSchool.school_id));
		Toaster.showSuccess("הפרטים נשמרו בהצלחה");
	};

	if(loading) {
		return (
			<PageBase title={pageTitle} />
		);
	}

	const closePopup = () => {
		setLoading(true);
		setEditingTeacher(null);
		doClearSelectedRows();
	};

	const doClearSelectedRows = () => {
		setSelectedRow([]);
		setClearSelectedRows(true);
		setSelectedFilter('');
		setTimeout(() => {
			setClearSelectedRows(false);
			setLoading(false);
		}, 10);
	};

	const onEditTeacher = () => {
		if(selectedSchool.is_locked) {
			Toaster.showWarn("בית הספר נעול לעריכה.");
			doClearSelectedRows();
		}
		else {
			setEditingTeacher(selectedRows[0]);
		}
	};

	const onChangeFilters = filterBy => {
		setSelectedFilter(filterBy);
	};

	const filteredTeachers = teachers.filter(t => t.teacher_name.indexOf(selectedFilter) > -1);

	return (
		<PageBase title={pageTitle}>
			<PopupWin
				visible={editingTeacher != null}
				onClose={() => closePopup()}
				title="עריכת פרטי מורה"
				popupStyle={{width: '520px'}}>
				<EditTeacher teacher={editingTeacher} onAfterUpdate={onAfterUpdate} onClose={() => setEditingTeacher(null)} />
			</PopupWin>

			<CustomDataTable
				uid="teachers"
				columns={columns}
				data={filteredTeachers || []}
				selectableRows
				clearSelectedRows={clearSelectedRows}
				onSelectedRowsChange={state => setSelectedRow(state.selectedRows)}
				headerComponent={<FilterRow
					selectedRows={selectedRows}
					onEditTeacher={onEditTeacher}
					onFilter={(filterBy) => onChangeFilters(filterBy)}/>
				}
			/>
		</PageBase>
	)
};

export default Teachers;
