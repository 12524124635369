import React, {Component} from 'react';
import {toast, ToastContainer} from 'react-toastify';
import Icon from 'react-web-vector-icons';
import constants from "../../utils/constants";

class ToastManager extends Component {
	async handleToastMessage(msg, type) {
		switch(type) {
			case constants.TOAST_TYPE_INFO:
				toast.info(<div><Icon name='info' font='Octicons' color='#fff' size={18} />&nbsp;{msg}</div>);
				break;
			case constants.TOAST_TYPE_SUCCESS:
				toast.success(<div><Icon name='info' font='Octicons' color='#fff' size={18} />&nbsp;{msg}</div>);
				break;
			case constants.TOAST_TYPE_WARN:
				toast.warn(<div><Icon name='info' font='Octicons' color='#fff' size={18} />&nbsp;{msg}</div>);
				break;
			case constants.TOAST_TYPE_ERROR:
				toast.error(<div><Icon name='info' font='Octicons' color='#fff' size={18} />&nbsp;{msg}</div>);
				break;
			default:
				toast(<div><Icon name='info' font='Octicons' color='#fff' size={18} />&nbsp;{msg}</div>);
				break;
		}
	}

	render() {
		return (
			<ToastContainer
				position="top-left"
				closeOnClick
				draggable
				pauseOnHover
			/>
		)
	}
}

export default ToastManager;
