import React from 'react';

const ParentAuth = props => {

	return (
		<div></div>
	)
};


export default ParentAuth;