import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Input, Select, ToolbarButton} from "../../../components/FormFields/index";
import * as schoolActions from "../../../redux/School/actions";
import Toaster from "../../../components/Toaster";

import './editStudents.scss';
import {isNullOrEmptyArray} from "../../../utils/general";

const log = require("loglevel").getLogger("EditUser");
log.setLevel("error");

const EditStudents = props => {
	const { school, onAfterUpdate, onClose } = props;
	const [studentData, setStudentData] = useState(props.studentData ? props.studentData : null);
	const [search, setSearch] = useState('');
	const [studentList, setStudentList] = useState(null);
	const classes = useSelector(state => state.school.classes);
	const dispatch = useDispatch();

	useEffect(() => {
		const Load = async () => {
			if(school) {
				await dispatch(schoolActions.getClasses(school.school_id));
			}
		};
		Load();
	}, [dispatch, school]);

	const onCancel = () => {
		// if edit student - first go back to search screen we came from
		if(studentData != null && studentData.student_id !== "-1") {
			setStudentData(null);
		}
		else {
			onClose();
		}
	};

	if(!school) {
		return <div/>;
	}

	const onSave = () => {
		if(!validateField(studentData['student_name'], 3)) {
			Toaster.showWarn("חובה להזין את שם התלמיד");
		}
		else if(studentData['parent1_name'].length > 0 && studentData['parent1_phone'].length !== 11) {
			Toaster.showWarn("מספר הטלפון של הורה 1 אינו תקין. יש להזין מספר במבנה 05X-1234567");
		}
		else if(studentData['class_id'] < 0) {
			Toaster.showWarn("חובה לבחור כיתה");
		}
		else if(studentData['parent2_name'].length > 0 && studentData['parent2_phone'].length !== 11) {
			Toaster.showWarn("מספר הטלפון של הורה 2 אינו תקין. יש להזין מספר במבנה 05X-1234567");
		}
		else if(studentData['parent1_name'].length < 1 && studentData['parent1_phone'].length > 0) {
			Toaster.showWarn("השם של הורה 1 אינו תקין");
		}
		else if(studentData['parent2_name'].length < 1 && studentData['parent2_phone'].length > 0) {
			Toaster.showWarn("השם של הורה 2 אינו תקין");
		}
		else {
			dispatch(schoolActions.updateStudent(studentData, saveCallback));
		}
	};

	const validateField = (value, minLength) => {
		return value != null && value.length >= minLength;
	};

	const saveCallback = (result) => {
		if(result === "ok") {
			onAfterUpdate();
		}
		else {
			Toaster.showWarn("איראה תקלה. נסה שוב.");
		}
	};

	const onSearch = () => {
		dispatch(schoolActions.searchStudent(search, school.school_id, onSearchDone));
	};

	const onSearchDone = result => {
		if(result.status === 'exact') {
			setStudentData(result.data);
		}
		else if(result.status === 'list' && result.data.length > 0) {
			setStudentList(result.data);
		}
		else {
			Toaster.showWarn("לא נמצאו תוצאות.");
		}
	};

	const loadStudentDetails = async student => {
		await dispatch(schoolActions.loadStudentDetails(student.student_id, studentDetailsLoaded));
	};

	const studentDetailsLoaded = data => {
		setStudentData(data);
	};

	const renderPickStudent = studentList => {
		return (
			<div>
				{renderSearchStudent()}
				<div className="edit-student-search-result-table">
					{ studentList.map((st, i) => {
						return (
							<div key={i} className="edit-student-search-result-row">
								<div style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => loadStudentDetails(st)}>בחר</div>
								<div>{st.class_name}</div>
								<div>{st.student_name}</div>
							</div>
						)
					})}
				</div>
			</div>
		)
	};

	const updateStudentField = (field, value) => {
		const obj = {...studentData};
		obj[field] = value;
		setStudentData(obj);
	};

	const renderStudentDataField = () => {
		return (
			<div style={{paddingBottom: '20px'}}>
				<div className="formRow">
					<Input placeholder="שם התלמיד" maxLength={20} value={studentData.student_name} onChange={(e) => updateStudentField('student_name', e.target.value)} style={{width: 'auto'}} />
					<div className="fieldCaption">שם התלמיד</div>
				</div>

				<div className="formRow">
					<Select style={{width: 'auto', direction: 'rtl' }} value={studentData.class_id} onChange={e => updateStudentField('class_id', e.target.value)}>
						<option value={-1}>בחר כיתה</option>
						{ classes.map(c => <option key={c.class_id} value={c.class_id}>{c.class_name}</option>)}
					</Select>
					<div className="fieldCaption">כיתה</div>
				</div>

				<div className="formRow">
					<Input placeholder="שם הורה 1" maxLength={20} value={studentData.parent1_name} onChange={(e) => updateStudentField('parent1_name', e.target.value)} style={{width: 'auto'}} />
					<div className="fieldCaption">שם הורה 1</div>
				</div>

				<div className="formRow">
					<Input placeholder="מספר טלפון במבנה 05X-1234567" maxLength={20} value={studentData.parent1_phone} onChange={(e) => updateStudentField('parent1_phone', e.target.value)} style={{width: 'auto'}} />
					<div className="fieldCaption">טלפון הורה 1</div>
				</div>

				<div className="formRow">
					<Input placeholder="שם הורה 2" maxLength={20} value={studentData.parent2_name} onChange={(e) => updateStudentField('parent2_name', e.target.value)} style={{width: 'auto'}} />
					<div className="fieldCaption">שם הורה 2</div>
				</div>

				<div className="formRow">
					<Input placeholder="מספר טלפון במבנה 05X-1234567" maxLength={20} value={studentData.parent2_phone} onChange={(e) => updateStudentField('parent2_phone', e.target.value)} style={{width: 'auto'}} />
					<div className="fieldCaption">טלפון הורה 2</div>
				</div>

				<div className="formRow">
					<div style={{direction: 'rtl', display: 'flex', alignItems: 'center', userSelect: 'none'}}>
						<input type="radio" id="permit-required" name="permit-type" onChange={() => updateStudentField('permanent_release', 0)} value="permit-required" checked={studentData.permanent_release === 0} />
						<label htmlFor="permit-required">חייב אישור</label>
						<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
						<input type="radio" id="permit-not-required" name="permit-type" onChange={() => updateStudentField('permanent_release', 1)} value="permit-not-required" checked={studentData.permanent_release === 1} />
						<label htmlFor="permit-not-required">רשאי לצאת</label>
					</div>
					<div className="fieldCaption">סוג אישור</div>
				</div>
			</div>
		)
	};

	const renderSearchStudent = () => {
		return (
			<div className="formRow" style={{gridTemplateColumns: '100px 1fr'}}>
				<ToolbarButton onClick={onSearch} style={{marginRight: '10px'}}>חפש</ToolbarButton>
				<Input placeholder="שם התלמיד/ה" maxLength={20} value={search} onChange={(e) => setSearch(e.target.value)} style={{width: 'auto'}} />
			</div>
		)
	};

	return (
		<div className="edit-student-popup">
			{ school.is_locked ? <div style={{textAlign: 'center', padding: '10px', color: 'red', fontSize: '20px'}}>בית הספר נעול לשינויים</div> : '' }
			<div className="formWrapper">
				<div className="formRow">
					<Input disabled value={school && school.school_id} style={{width: 'auto'}} />
					<div className="fieldCaption">קוד מוסד</div>
				</div>

				<div className="formRow">
					<Input disabled value={school && school.school_name} style={{width: 'auto'}} />
					<div className="fieldCaption">שם בית הספר</div>
				</div>

				{
					studentData ? renderStudentDataField() :
						!isNullOrEmptyArray(studentList) ? renderPickStudent(studentList) :
							renderSearchStudent()
				}

			</div>

			{
				studentData ?
					<div className="popup-footer">
						<ToolbarButton onClick={onCancel}>חזור</ToolbarButton>
						<div style={{width: '20px'}}/>
						{ !school.is_locked && <ToolbarButton onClick={onSave}>שמור שינויים</ToolbarButton> }
					</div>
					:
					<div className="popup-footer">
						<ToolbarButton onClick={onClose}>סגור</ToolbarButton>
						<div style={{width: '20px'}}/>
						<ToolbarButton onClick={onSearch}>חפש תלמיד/ה</ToolbarButton>
					</div>
			}
		</div>
	);
};

export default EditStudents;
