import React, {useState} from 'react';
import Icon from 'react-web-vector-icons';

const PassField = props => {
	const [showPass, setShowPass] = useState(false);
	const value = props.children;

	return (
		<div style={{display: 'flex', alignItems: 'center', userSelect: 'none'}}>
			<div style={{width: '40px'}}>{showPass ? value : '*******'}</div>
			<div onClick={() => setShowPass(!showPass)}>
				<Icon name={showPass ? 'eye-off' : 'eye'} font="Feather" color="black" size={20} style={{padding: '5px', cursor: 'pointer'}} />
			</div>
		</div>
	)
};

export default PassField;