import * as actionTypes from './actionTypes';
import * as userService from './service'
import constants from "../../utils/constants";

const log = require("loglevel").getLogger("UserActions");
log.setLevel("error");

export const signIn = (user, idToken, onSuccess, onFail) => {
	return async (dispatch) => {
		log.debug("UserActions -> signIn -> Enter");

		const userData = await userService.signIn(user, idToken);

		log.debug("UserActions -> signIn -> User signed in. userData = ", userData);

		if(userData) {
			await dispatch(setLoggedInUser(userData));
			onSuccess();
		}
		else {
			onFail();
		}
	}
};

export const setLoggedInUser = userData => {
	log.debug("UserActions -> setLoggedInUser -> Enter");
	return async (dispatch) => {
		await dispatch ({
			type: actionTypes.USER_SIGNED_IN,
			data: userData
		});
	}
};

export const signOut = () => {
	return async (dispatch) => {
		const logoutResult = await userService.signOut();
		log.debug("signOut result", logoutResult);

		await dispatch({
			type: actionTypes.USER_SIGNED_OUT
		})
	};
};

export const markInvoicePaid = (invoice_id, client_id) => {
	return async (dispatch) => {
		log.debug("userActions -> markInvoicePaid -> Enter");
		await userService.markInvoicePaid(invoice_id);
		await dispatch(loadClientInvoices(client_id));
	}
};

export const deleteInvoice = (invoice_id, client_id, callback) => {
	return async (dispatch) => {
		log.debug("userActions -> deleteInvoice -> Enter");
		await userService.deleteInvoice(invoice_id, client_id);
		await dispatch(loadClientInvoices(client_id));

		callback && callback();
	}
};

export const updateInvoice = (invoice, callback) => {
	return async (dispatch) => {
		log.debug("userActions -> updateInvoice -> Enter");
		await userService.updateInvoice(invoice);

		callback && callback();
	}
};

export const loadPhotos = (guiFilters, sortData, requestedPageNumber) => {
	return async (dispatch) => {
		log.debug("userActions -> loadPhotos -> Enter");

		await dispatch({
			type: actionTypes.CLIENT_PHOTOS_LOADED,
			data: { photos: [], count: 0 }
		});

		const photoResponse = await userService.loadPhotos(guiFilters, sortData, requestedPageNumber);
		const clientsIds = {};
		photoResponse.photos.forEach(photo => clientsIds[photo.client_id] = 1);
	}
};

export const approvePhoto = (photo_id, updated_title, callback) => {
	return async (dispatch) => {
		log.debug("userActions -> approvePhoto -> Enter");
		await userService.approvePhoto(photo_id, updated_title);
		callback();
	}
};

export const declinePhoto = (photo_id, reason, callback) => {
	return async (dispatch) => {
		log.debug("userActions -> declinePhoto -> Enter");
		await userService.declinePhoto(photo_id, reason);
		callback();
	}
};


export const sendEmailToClients = (source, templateId, clientIds, callback) => {
	return async (dispatch) => {
		log.debug("PortalActions -> sendEmailToClients -> Enter");
		await userService.sendEmailToClients(source, templateId, clientIds);

		callback();
	}
};

export const uploadGalleryPhoto = (client_id, url, title, callback) => {
	return async (dispatch) => {
		log.debug("userActions -> uploadGalleryPhoto -> Enter");
		// const uploaded = await userService.uploadGalleryPhoto(client_id, url, title);
 	}
};

export const loadClientInvoices = client_id => {
	return async (dispatch) => {
		log.debug("userActions -> loadClientInvoices -> Enter");
		const invoices = await userService.loadClientInvoices(client_id);

		await dispatch({
			type: actionTypes.CLIENT_INVOICES_LOADED,
			data: invoices,
			client_id
		});
	}
};

export const uploadInvoice = (path, callback) => {
	return async (dispatch) => {
		const response = await userService.uploadInvoice(path);

		let result;
		if(response.status === "ok") {
			const invoice_id = response.data;
			const invoice = await userService.loadInvoiceById(invoice_id);
			invoice.client = await userService.loadClientById(invoice.client_id);
			result = { status: "ok", data: invoice };
		}
		else {
			result = { status: "error", data: response.data };
		}
		callback(result);
	};
};

export const uploadInvoices = (urls, callback) => {
	return async (dispatch) => {
		const response = await userService.uploadInvoices(urls);
		callback(response.data);
	};
};

export const loadInvoiceById = (client_id, invoice_id) => {
	return async (dispatch) => {
		const invoice = await userService.loadInvoiceById(invoice_id);

		await dispatch({
			type: actionTypes.CLIENT_INVOICES_LOADED,
			data: [invoice],
			client_id
		});
	};
};

export const resetState = () => {
	log.debug("UserActions -> performing resetState");
	return {
		type: constants.GLOBAL_ACTION_INIT_STATE
	}
};
