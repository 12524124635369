import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import PageBase from "../../components/PageBase";

import './home.scss';
import * as schoolActions from "../../redux/School/actions";

const DataBox = props => {
	const {title, value} = props;

	return (
		<div className="home-data-box-container">
			<div className="home-data-box-title">{title}</div>
			<div className="home-data-box-value">{value}</div>
		</div>
	)
};

const Home = props => {
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();
	const availableSms = useSelector(state => state.school.availableSms);
	const schools = useSelector(state => state.school.schools);

	useEffect(() => {
		const Load = async () => {
			await dispatch(schoolActions.getAvailableSms());
			await dispatch(schoolActions.getSchools());
			setLoading(false);
		};
		Load();
	}, [dispatch]);

	if(loading) {
		return (
			<PageBase title="דף הבית" />
		)
	}

	return (
		<PageBase title="דף הבית">
			<div className="home-container">
				<DataBox title="SMS Left" value={availableSms} />
				<DataBox title="Active Schools" value={schools.length} />
				<DataBox title="SMS Sent Today" value="-" />
				<DataBox title="SMS Sent This Week" value="-" />
			</div>
		</PageBase>
	)
};

export default Home;
