
const constants = {
	SIDEBAR_STATE_STORAGE_KEY: 'open',
	SIDEBAR_MODE_OPEN: 'SIDEBAR_MODE_OPEN',
	SIDEBAR_MODE_CLOSED: 'SIDEBAR_MODE_CLOSED',

	GLOBAL_ACTION_INIT_STATE: 'GLOBAL_ACTION_INIT_STATE',

	STORAGE_SIGNED_USER_KEY: "mypass-user-data",

	MENU_OPEN_CATEGORIES: "menu-open-categories",

	USER_ROLE_ADMIN: 'user-role-admin',

	PAGE_AUTH_TYPE_NONE: 'page-auth-none',
	PAGE_AUTH_TYPE_USER: 'page-auth-user',
	PAGE_AUTH_TYPE_ADMIN: 'page-auth-admin',

	TOAST_TYPE_INFO: 'toast_info',
	TOAST_TYPE_SUCCESS: 'toast_success',
	TOAST_TYPE_WARN: 'toast_warn',
	TOAST_TYPE_ERROR: 'toast_error',

	CACHE_LAYER_RESULT_TYPE_NONE: 'none',
	CACHE_LAYER_RESULT_TYPE_PARTIAL: 'partial',
	CACHE_LAYER_RESULT_TYPE_FULL: 'full',

	TOP_HEIGHT: 55,
	PAGE_TITLE_HEIGHT: 50,
	POPUP_TITLE_HEIGHT: 70,
	DEFAULT_VIDEO_LIST_PAGE_SIZE: 15,
	DEFAULT_CLIENT_LIST_PAGE_SIZE: 20,
	DEFAULT_CLIENT_PHOTO_LIST_PAGE_SIZE: 20,

};

export default constants;
