import React, {useState} from 'react';
import FiltersRow from "../../components/FiltersRow";
import {Input, ToolbarButton} from "../../components/FormFields";
import Icon from 'react-web-vector-icons';
import {isNullOrEmptyArray} from "../../utils/general";

const FilterRow = props => {
	const [filterBy, setFilterBy] = useState('');
	const { onFilter, selectedRows, onEditTeacher } = props;

	const onChangeFilter = e => {
		setFilterBy(e.target.value);
		onFilter(e.target.value);
	};

	const editIcon = <Icon name='edit' font='Feather' color="000" size={15} style={{paddingLeft: '6px'}} />;
	const disabled = isNullOrEmptyArray(selectedRows) || selectedRows.length > 1;

	return (
		<FiltersRow onClear={() => this.onClear()}
		            fields={[ {
			            component: (
				            <Input placeholder="הקלד לסנן..." value={filterBy} onChange={onChangeFilter} style={{width: '250px'}} />
			            )
		            } ]}
		            buttons={[
			            <ToolbarButton key={4} disabled={disabled} style={{marginLeft: '10px'}} rightIcon={editIcon} onClick={disabled ? null : onEditTeacher}> ערוך פרטים</ToolbarButton>
		            ]}
		/>
	);
}

export default FilterRow;
