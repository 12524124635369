import React from 'react';
import Header from './Header';
import Menu from './Menu';
import PageBaseContent from './PageBaseContent';
import './pageBase.scss';
import {isTrue} from "../../utils/general";

const log = require("loglevel").getLogger("PageBase");
log.setLevel("debug");

const PageBase = props => {
	const { hideHeader, loading, title, removeMargins, children } = props;

	if(isTrue(loading)) {
		return <div />;
	}

	return (
		<div className="pageBaseWrapper">
			<div className="pageBaseContainer">
				<div className="pageBase">
					{ !hideHeader && <Header /> }
					<div className="pageBaseContent">
						<div className="pageBaseInnerBody">
							{
								title &&
								<div className="pageBaseTitleContainer">
									<div className="pageBaseTitle">{ title }</div>
								</div>
							}

							<PageBaseContent removeMargins={removeMargins} hasTitle={title != null} allowScroll>
								{ children }
							</PageBaseContent>
						</div>
					</div>
				</div>
				<Menu />
			</div>
		</div>
	)
};

export default PageBase;
