import React, {useState} from 'react'
import {useDispatch} from "react-redux";
import {Input, ToolbarButton} from "../../../components/FormFields/index";
import * as schoolActions from "../../../redux/School/actions";
import Toaster from "../../../components/Toaster";

import './editTeacher.scss';

const log = require("loglevel").getLogger("EditUser");
log.setLevel("error");

const EditTeacher = props => {
	const { teacher, onAfterUpdate, onClose } = props;
	const [name, setName] = useState(teacher && teacher.teacher_name);
	const [phone, setPhone] = useState(teacher && teacher.teacher_phone);
	const dispatch = useDispatch();

	const onSave = props => {
		if(name.length < 3) {
			Toaster.showWarn("חובה למלא שם תקין");
			return;
		}
		else if(phone.length !== 11) {
			Toaster.showWarn("חובה למלא מספר טלפון תקין במבנה 05X-1234567");
			return;
		}

		dispatch(schoolActions.updateTeacher(teacher.teacher_id, name, phone, saveCallback));
	};

	const saveCallback = (result) => {
		if(result === "ok") {
			onAfterUpdate();
		}
		else {
			Toaster.showWarn("איראה תקלה. נסה שוב.");
		}
	};

	return (
		<div className="edit-user-popup">

			<div className="formWrapper">
				<div className="formRow">
					<Input placeholder="שם המורה" value={name} onChange={(e) => setName(e.target.value)} style={{width: '100%'}} />
					<div className="fieldCaption">שם המורה</div>
				</div>

				<div className="formRow">
					<Input placeholder="מספר טלפון" value={phone} onChange={(e) => setPhone(e.target.value)} style={{width: '100%', direction: 'ltr'}} />
					<div className="fieldCaption">מספר טלפון</div>
				</div>

			</div>

			<div className="popup-footer">
				<ToolbarButton onClick={onClose}>בטל</ToolbarButton>
				<div style={{width: '20px'}} />
				<ToolbarButton onClick={onSave}>שמור שינויים</ToolbarButton>
			</div>
		</div>
	);
};

export default EditTeacher;
