import axios from 'axios';
import constants from "../../utils/constants";

const log = require("loglevel").getLogger("AdminService");
log.setLevel("debug");

export const getRoles = async () => {
	log.debug("AdminService -> getRoles -> Enter");

	return axios.post(`${constants.BACKEND_URL}/admin/roles/get`, {})
		.then(async response => {
			return response.data.roles;
		})
		.catch(response => {
			log.debug("AdminService -> getRoles -> error: ");
			log.debug(response);
			return null;
		})
};

export const getSchools = async () => {
	log.debug("AdminService -> getSchools -> Enter");

	return axios.get(`${process.env.REACT_APP_BACKEND_URL}/school/schools`)
		.then(async response => {
			return response.data.schools;
		})
		.catch(response => {
			log.debug("AdminService -> getSchools -> error: ");
			log.debug(response);
			return null;
		})
};

export const getClasses = async school_id => {
	log.debug("AdminService -> getClasses -> Enter");

	return axios.post(`${process.env.REACT_APP_BACKEND_URL}/school/classes`, { school_id })
		.then(async response => {
			return response.data.classes;
		})
		.catch(response => {
			log.debug("AdminService -> getClasses -> error: ");
			log.debug(response);
			return null;
		})
};

export const getTeachers = async school_id => {
	log.debug("AdminService -> getTeachers -> Enter");

	return axios.post(`${process.env.REACT_APP_BACKEND_URL}/school/teachers`, { school_id })
		.then(async response => {
			return response.data.teachers;
		})
		.catch(response => {
			log.debug("AdminService -> getTeachers -> error: ");
			log.debug(response);
			return null;
		})
};

export const toggleLockSchool = async school_id => {
	log.debug("AdminService -> toggleLockSchool -> Enter");

	return axios.post(`${process.env.REACT_APP_BACKEND_URL}/school/locked/change`, { school_id })
		.then(async response => {
			return 'ok';
		})
		.catch(response => {
			log.debug("AdminService -> toggleLockSchool -> error: ");
			log.debug(response);
			return null;
		})
};

export const getAvailableSms = async () => {
	log.debug("AdminService -> getAvailableSms -> Enter");

	return axios.get(`${process.env.REACT_APP_BACKEND_URL}/imi/available`)
		.then(async response => {
			return response.data.left;
		})
		.catch(response => {
			log.debug("AdminService -> getAvailableSms -> error: ");
			log.debug(response);
			return null;
		})
};

export const updateTeacher = async (teacher_id, name, phone) => {
	log.debug("AdminService -> updateTeacher -> Enter");

	return axios.post(`${process.env.REACT_APP_BACKEND_URL}/school/teacher/update`, { teacher_id, name, phone })
		.then(async response => {
			return "ok";
		})
		.catch(response => {
			log.debug("AdminService -> updateTeacher -> error: ");
			log.debug(response);
			return "error";
		})
};

export const searchStudent = async (name, school_id) => {
	log.debug("AdminService -> searchStudent -> Enter");

	return axios.post(`${process.env.REACT_APP_BACKEND_URL}/school/student/search`, { name, school_id })
		.then(async response => {
			return response.data;
		})
		.catch(response => {
			log.debug("AdminService -> updateTeacher -> error: ");
			log.debug(response);
			return { status: 'error' };
		})
};

export const loadStudentDetails = async student_id => {
	log.debug("AdminService -> loadStudentDetails -> Enter");

	return axios.post(`${process.env.REACT_APP_BACKEND_URL}/school/student/data`, { student_id })
		.then(async response => {
			return response.data.data;
		})
		.catch(response => {
			log.debug("AdminService -> loadStudentDetails -> error: ");
			log.debug(response);
			return { status: 'error' };
		})
};

export const updateSchool = async (school_id, name, guardCode, adminCode, logo) => {
	log.debug("AdminService -> updateSchool -> Enter");

	return axios.post(`${process.env.REACT_APP_BACKEND_URL}/school/update`, { school_id, name, guardCode, adminCode, logo })
		.then(async response => {
			return "ok";
		})
		.catch(response => {
			log.debug("AdminService -> updateSchool -> error: ");
			log.debug(response);
			return "error";
		})
};

export const updateStudent = async (studentData) => {
	log.debug("AdminService -> updateStudent -> Enter");

	return axios.post(`${process.env.REACT_APP_BACKEND_URL}/school/student/update`, { studentData })
		.then(async response => {
			return "ok";
		})
		.catch(response => {
			log.debug("AdminService -> updateStudent -> error: ");
			log.debug(response);
			return "error";
		})
};
