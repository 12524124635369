import React from 'react';
import {useSelector} from "react-redux";
import {Link} from 'react-router-dom';
import imgLogo from '../../../assets/logomp.jpeg';
import * as ROUTES from '../../../utils/routes';
import './header.scss';

const Header = props => {
	const user = useSelector(state => state.user.user);

	const renderUser = () => {
		if(user) {
			return (
				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					<div className="font2 fontSize12" style={{color: '#7F8384', cursor: 'default'}}>Welcome back {user.displayName}</div>
				</div>
			)
		}

		return <div/>
	};

	return (
		<div className="header">
			<div className="header-top">
				{ renderUser() }

				<Link to={ROUTES.HOME}>
					<img src={imgLogo} alt="Version 0.102" title="Beta 07.05.21" className="logo" />
				</Link>
			</div>
		</div>
	)
};

export default Header;
