import React, {useState} from 'react';
import FiltersRow from "../../components/FiltersRow";
import {Input, ToolbarButton} from "../../components/FormFields";
import Icon from 'react-web-vector-icons';
import {isNullOrEmptyArray} from "../../utils/general";

const FilterRow = props => {
	const [filterBy, setFilterBy] = useState('');
	const { onFilter, selectedRows, onAddSchool, onToggleLock, onEditSchool, onShowTeachers, onShowStudents, onAddStudent } = props;

	const onChangeFilter = e => {
		setFilterBy(e.target.value);
		onFilter(e.target.value);
	};

	const plusIcon = <Icon name='plussquareo' font='AntDesign' color="000" size={14} style={{paddingLeft: '6px'}} />;
	const editIcon = <Icon name='edit' font='Feather' color="000" size={15} style={{paddingLeft: '6px'}} />;
	const lockIcon = <Icon name='lock' font='SimpleLineIcons' color="000" size={20} style={{paddingLeft: '6px'}} />;
	const disabled = isNullOrEmptyArray(selectedRows) || selectedRows.length > 1;

	let i=0;

	return (
		<FiltersRow onClear={() => this.onClear()}
		            fields={[ {
			            component: (
				            <Input placeholder="Type filter..." value={filterBy} onChange={onChangeFilter} style={{width: '250px'}} />
			            )
		            } ]}
		            buttons={[
			            <ToolbarButton key={i++} style={{marginLeft: '15px'}} rightIcon={plusIcon} onClick={onAddSchool}>הוסף בית ספר חדש</ToolbarButton>,
			            <ToolbarButton key={i++} disabled={disabled} style={{marginLeft: '15px'}} rightIcon={lockIcon} onClick={disabled ? null : onToggleLock}>נעל / שחרר</ToolbarButton>,
			            <ToolbarButton key={i++} disabled={disabled} style={{marginLeft: '15px'}} rightIcon={editIcon} onClick={disabled ? null : onShowStudents}>עריכת פרטי תלמידים</ToolbarButton>,
			            <ToolbarButton key={i++} disabled={disabled} style={{marginLeft: '15px'}} rightIcon={plusIcon} onClick={disabled ? null : onAddStudent}>הוספת תלמיד</ToolbarButton>,
			            <ToolbarButton key={i++} disabled={disabled} style={{marginLeft: '15px'}} rightIcon={editIcon} onClick={disabled ? null : onShowTeachers}>רשימת מורים</ToolbarButton>,
			            <ToolbarButton key={i++} disabled={disabled} style={{marginLeft: '15px'}} rightIcon={editIcon} onClick={disabled ? null : onEditSchool}>פרטי בית הספר</ToolbarButton>
		            ]}
		/>
	);
}

export default FilterRow;
