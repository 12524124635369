import React, {useState} from 'react'
import {useDispatch} from "react-redux";
import {Input, ToolbarButton} from "../../../components/FormFields/index";
import * as schoolActions from "../../../redux/School/actions";
import Toaster from "../../../components/Toaster";

import './editSchool.scss';

const log = require("loglevel").getLogger("EditUser");
log.setLevel("error");

const EditSchool = props => {
	const { school, onAfterUpdate, onClose } = props;
	const [name, setName] = useState(school && school.school_name);
	const [guardCode, setGuardCode] = useState(school && school.guard_pass);
	const [adminCode, setAdminCode] = useState(school && school.admin_pass);
	const [logo, setLogo] = useState(school && school.logo_path);
	const dispatch = useDispatch();

	if(!school) {
		return <div/>;
	}

	const onSave = () => {
		if(!validateField(guardCode, 4)) {
			Toaster.showWarn("חובה להזין קוד שומר בן 4 תוים לפחות");
		}
		else if(!validateField(adminCode, 4)) {
			Toaster.showWarn("חובה להזין קוד מזכירות בן 4 תוים לפחות");
		}
		else {
			dispatch(schoolActions.updateSchool(school.school_id, name, guardCode, adminCode, logo, saveCallback));
		}
	};

	const validateField = (value, minLength) => {
		return value != null && value.length >= minLength;
	};

	const saveCallback = (result) => {
		if(result === "ok") {
			onAfterUpdate();
		}
		else {
			Toaster.showWarn("איראה תקלה. נסה שוב.");
		}
	};

	return (
		<div className="edit-user-popup">

			<div className="formWrapper">
				<div className="formRow">
					<Input disabled value={school && school.school_id} onChange={(e) => setName(e.target.value)} style={{width: '100%'}} />
					<div className="fieldCaption">קוד מוסד</div>
				</div>

				<div className="formRow">
					<Input placeholder="שם בית הספר כפי שיופיע במערכת השיחרורים" maxLength={50} value={name} onChange={(e) => setName(e.target.value)} style={{width: '100%'}} />
					<div className="fieldCaption">שם לתצוגה</div>
				</div>

				<div className="formRow">
					<Input placeholder="קוד שומר" maxLength={20} value={guardCode} onChange={(e) => setGuardCode(e.target.value)} style={{width: '100%', direction: 'ltr'}} />
					<div className="fieldCaption">קוד שומר</div>
				</div>

				<div className="formRow">
					<Input placeholder="קוד מזכירות" maxLength={20} value={adminCode} onChange={(e) => setAdminCode(e.target.value)} style={{width: '100%', direction: 'ltr'}} />
					<div className="fieldCaption">קוד מזכירות</div>
				</div>

				<div className="formRow">
					<Input placeholder="לוגו" maxLength={250} value={logo} onChange={(e) => setLogo(e.target.value)} style={{width: '100%', direction: 'ltr'}} />
					<div className="fieldCaption">לוגו</div>
				</div>

			</div>

			<div className="popup-footer">
				<ToolbarButton onClick={onClose}>בטל</ToolbarButton>
				<div style={{width: '20px'}} />
				<ToolbarButton onClick={onSave}>שמור שינויים</ToolbarButton>
			</div>
		</div>
	);
};

export default EditSchool;
