import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Icon from 'react-web-vector-icons';
import * as userActions from '../../../redux/User/actions';
import * as ROUTES from "../../../utils/routes";
import constants from "../../../utils/constants";

import './menu.scss';
import {setStorageItem} from "../../../utils/storageUtils";

const SIDEBAR_WIDTH = '275px';

const Menu = props => {
	const [loading, setLoading] = useState(true);
	const [menuMode, setMenuMode] = useState(constants.SIDEBAR_MODE_OPEN);
	const user = useSelector(state => state.user.user);
	const dispatch = useDispatch();

	useEffect(() => {
		const Load = async () => {
			const mode = localStorage.getItem(constants.SIDEBAR_STATE_STORAGE_KEY);
			setMenuMode(mode || constants.SIDEBAR_MODE_OPEN);
			setLoading(false);
		};
		Load();
	}, [dispatch]);

	const renderMenuItem = (key, label, action, icon) => {
		const textClassName = (menuMode === constants.SIDEBAR_MODE_OPEN) ? 'menu-item-text' : 'menu-item-text-hidden';

		if(typeof action === 'string') {
			return (
				<Link key={key} className="menu-item" to={action}>
					<div className={textClassName} onMouseOver={e => e.currentTarget.style.color='#b7cfe0'} onMouseOut={e => e.currentTarget.style.color='#4e5ba7'}>{label}</div>
					<div className="menu-item-icon">{ icon }</div>
				</Link>
			)
		}
		else if(typeof action === 'function') {
			return (
				<div key={key} className="menu-item" onClick={action}>
					<div className={textClassName} onMouseOver={e => e.currentTarget.style.color='#b7cfe0'} onMouseOut={e => e.currentTarget.style.color='#4e5ba7'}>{label}</div>
					<div className="menu-item-icon">{ icon }</div>
				</div>
			)
		}

		return (
			<div key={key} className="menu-item">
				<div className={textClassName} onMouseOver={e => e.currentTarget.style.color='#b7cfe0'} onMouseOut={e => e.currentTarget.style.color='#4e5ba7'}>{label}</div>
				<div className="menu-item-icon">{ icon }</div>
			</div>
		)
	};

	const toggleMenu = () => {
		const newMode = menuMode === constants.SIDEBAR_MODE_OPEN ? constants.SIDEBAR_MODE_CLOSED : constants.SIDEBAR_MODE_OPEN;
		setMenuMode(newMode);
		setStorageItem(constants.SIDEBAR_STATE_STORAGE_KEY, newMode);
	};

	const onSignout = () => {
		dispatch(userActions.signOut());
	};

	const getIcon = (name, family) => {
		return <Icon name={name} font={family} color="#4e5ba7" size={22} />;
	};

	if(loading) {
		return <div />;
	}

	const homeIcon = getIcon('home', 'Entypo');
	const menuIcon = getIcon('menu', 'Entypo');
	const schoolsIcon = getIcon('school', 'MaterialIcons');
	// const demoIcon = getIcon('account-edit', 'MaterialCommunityIcons');
	const logoutIcon = getIcon('logout', 'SimpleLineIcons');

	return (
		<div className="menu-wrapper" style={{ width: menuMode === constants.SIDEBAR_MODE_OPEN ? SIDEBAR_WIDTH : '60px' }}>
			{
				user &&
				<div onClick={toggleMenu} className="menu-trigger" style={{right: '18px'}}>
					{menuIcon}
				</div>
			}

			{ renderMenuItem('home', 'דף הבית', ROUTES.HOME, homeIcon ) }
			{ renderMenuItem('schools', 'בתי ספר', ROUTES.SCHOOLS, schoolsIcon ) }
			{/*{ renderMenuItem('demo', 'דמו', ROUTES.SCHOOLS, demoIcon ) }*/}

			{ <span className="menu-separator" /> }

			{ renderMenuItem('signout', 'התנתק', onSignout, logoutIcon ) }
		</div>
	)
};

export default Menu;
