import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import * as userActions from '../../redux/User/actions';
import {getAuth, signInWithPopup, GoogleAuthProvider} from "firebase/auth";

import './SignInPage.scss';

const SignInPage = props => {
	const [loading, setLoading] = useState(true);
	const [showError, setShowError] = useState(false);
	const dispatch = useDispatch();

	getAuth().onAuthStateChanged(async (loggedInUser) => {
		if (loggedInUser) {
			const idToken = await getAuth().currentUser.getIdToken(/* forceRefresh */ true);
			dispatch(userActions.signIn(loggedInUser, idToken, onSuccess, onFail));
		}
		else {
			console.log("AUTH STATE CHANGED. User signed out.");
			setLoading(false);
		}
	});

	const onSuccess = () => {
	};

	const onFail = async () => {
		await getAuth().signOut();
		await dispatch(userActions.signOut());
		setLoading(false);
		setShowError(true);
	};

	const onLogin = () => {
		const provider = new GoogleAuthProvider();
		const auth = getAuth();

		signInWithPopup(auth, provider)
			.then((result) => {
				console.log("LOGIN OK")
			})
			.catch((error) => {
				// Handle Errors here.
				// const errorCode = error.code;
				// const errorMessage = error.message;
				// const email = error.customData.email;
				// const credential = GoogleAuthProvider.credentialFromError(error);
			})
	};

	if(loading) {
		return <div />
	}

	return (
		<div className="main-app-container">
			<div className="signin-box-wrapper">
				<div className="signin-box">
					<div/>
					<div style={{fontSize: '20px', color: '#000'}}>Sign in</div>
					<div style={{fontSize: '20px', color: 'red'}}>{ showError && 'The user is not authorized' }&nbsp;</div>
					<div/>
					<img src="./images/btn_google_signin_dark_normal_web.png" alt="" style={{cursor: 'pointer'}} onClick={onLogin} />
					<div />
				</div>
			</div>
		</div>
	)
};

export default SignInPage;