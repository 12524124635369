import axios from 'axios';
import * as actionTypes from './actionTypes';
import constants from "../../utils/constants";
import { getAuth } from "firebase/auth";

const log = require("loglevel").getLogger("userReducer");
log.setLevel("debug");

const initialState = JSON.stringify({
	user: null
});

function userReducer(state = JSON.parse(initialState), action) {
	switch (action.type) {
		case constants.GLOBAL_ACTION_INIT_STATE: {
			log.debug("reset user state");
			return initialState;
		}
		case actionTypes.USER_SIGNED_IN: {
			const user = action.data;

			axios.defaults.headers.common['Mypass-Access'] = user.uid;

			return {
				...state,
				user
			};
		}
		case actionTypes.USER_SIGNED_OUT: {
			getAuth().signOut().then(() => {
				console.log("firebase signed out");
			});

			axios.defaults.headers.common['Mypass-Access'] = null;

			return {
				...state,
				user: null,
			};
		}
		default:
			return state;
	}
}

export default userReducer;
