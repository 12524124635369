import React from "react";
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import {useSelector} from "react-redux";
import {initializeApp} from "firebase/app";
import "firebase/auth";
import ToastManager from "./components/ToastManager";
import Login from "./pages/SignInPage";
import Home from "./pages/Home";
import {logger} from "./utils/utils";
import {pdfjs} from 'react-pdf';
import {APP_PARENT_AUTH, HOME, SCHOOLS, SIGN_IN, TEACHERS} from "./utils/routes";

import 'react-toastify/dist/ReactToastify.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './App.css';
import Schools from "./pages/Schools";
import Teachers from "./pages/Teachers";
import ParentAuth from "./pages/App/ParentAuth";

require('react-web-vector-icons/fonts');

pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID
};

initializeApp(firebaseConfig);

const App = () => {
	const user = useSelector(state => state.user.user);

	if(window.location.href.indexOf("/app/") > -1) {
		return (
			<Router>
				<Routes>
					<Route path={APP_PARENT_AUTH} element={<ParentAuth />} />
					<Route path="*" element={<Navigate to={APP_PARENT_AUTH} />} />
				</Routes>
			</Router>
		)
	}

	if(!user) {
		return (
			<Router>
				<Routes>
					<Route path={SIGN_IN} element={<Login />} />
					<Route path="*" element={<Navigate to={SIGN_IN} />} />
				</Routes>
			</Router>
		)
	}

	logger("App is Up");

	return (
		<div>
			<ToastManager />
			<Router>
				<Routes>
					<Route path={HOME} element={<Home />} />
					<Route path={SCHOOLS} element={<Schools />} />
					<Route path={TEACHERS} element={<Teachers />} />
					<Route path="*" element={<Navigate to={HOME} />} />
				</Routes>
			</Router>
		</div>
	)
};

export default App;