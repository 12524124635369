import * as actionTypes from './actionTypes';
import * as adminService from './service'

const log = require("loglevel").getLogger("SchoolActions");
log.setLevel("error");

export const getSchools = () => {
	return async (dispatch) => {
		log.debug("SchoolActions -> getSchools -> Enter");

		const schools = await adminService.getSchools();
		await dispatch ({
			type: actionTypes.SCHOOLS_LOADED,
			data: schools || []
		});
	}
};

export const getTeachers = school_id => {
	return async (dispatch) => {
		log.debug("SchoolActions -> getTeachers -> Enter");

		const teachers = await adminService.getTeachers(school_id);
		await dispatch ({
			type: actionTypes.TEACHERS_LOADED,
			data: teachers || []
		});
	}
};

export const getClasses = school_id => {
	return async (dispatch) => {
		log.debug("SchoolActions -> getClasses -> Enter");

		const classes = await adminService.getClasses(school_id);
		await dispatch ({
			type: actionTypes.CLASSES_LOADED,
			data: classes || []
		});
	}
};

export const updateSchool = (school_id, name, guardCode, adminCode, logo, callback) => {
	return async (dispatch) => {
		log.debug("SchoolActions -> updateSchool -> Enter");
		const result = await adminService.updateSchool(school_id, name, guardCode, adminCode, logo);
		callback(result);
	}
};

export const updateStudent = (studentData, callback) => {
	return async (dispatch) => {
		log.debug("SchoolActions -> updateStudent -> Enter");
		const result = await adminService.updateStudent(studentData);
		callback(result);
	}
};

export const updateTeacher = (teacher_id, name, phone, callback) => {
	return async (dispatch) => {
		log.debug("SchoolActions -> updateTeacher -> Enter");
		const result = await adminService.updateTeacher(teacher_id, name, phone);
		callback(result);
	}
};

export const searchStudent = (name, school_id, callback) => {
	return async (dispatch) => {
		log.debug("SchoolActions -> searchStudent -> Enter");
		const result = await adminService.searchStudent(name, school_id);
		callback(result);
	}
};

export const loadStudentDetails = (student_id, callback) => {
	return async (dispatch) => {
		log.debug("SchoolActions -> loadStudentDetails -> Enter");
		const result = await adminService.loadStudentDetails(student_id);
		callback(result);
	}
};

export const toggleLockSchool = school_id => {
	return async (dispatch) => {
		log.debug("SchoolActions -> toggleLockSchool -> Enter");
		await adminService.toggleLockSchool(school_id);
		dispatch(getSchools());
	}
};

export const getAvailableSms = () => {
	return async (dispatch) => {
		log.debug("SchoolActions -> getAvailableSms -> Enter");
		const result = await adminService.getAvailableSms();

		await dispatch ({
			type: actionTypes.UPDATE_AVAILABLE_SMS,
			data: result
		});
	}
};

export const setSelectedSchool = school_id => {
	return {
		type: actionTypes.SET_SELECTED_SCHOOL,
		data: school_id
	}
};

export const clearSelectedSchool = () => {
	return {
		type: actionTypes.SET_SELECTED_SCHOOL,
		data: -1
	}
};