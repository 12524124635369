import React from 'react';
import '../pageBase.scss';

const log = require("loglevel").getLogger("PageBase");
log.setLevel("debug");

const PageBaseContent = props => (
	<div className="PageBaseContentWithScroll" style={{width: props.removeMargins ? '100%' : 'calc(100% - 60px)', padding: props.removeMargins ? '0' : '0 30px', overflowY: 'auto'}}>
		{ props.children }
	</div>
);

export default PageBaseContent;
