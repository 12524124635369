import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import FilterRow from './FilterRow';
import * as schoolActions from "../../redux/School/actions";
import PageBase from "../../components/PageBase";
import CustomDataTable from "../../components/CustomDataTable";
import PopupWin from "../../components/PopupWin";

import './schools.scss';
import Toaster from "../../components/Toaster";
import PassField from "./PassField";
import {TEACHERS} from "../../utils/routes";
import EditSchool from "./EditSchool";
import EditStudents from "./EditStudents";

const log = require("loglevel").getLogger("AdminUsers");
log.setLevel("debug");

const Schools = props => {
	const [loading, setLoading] = useState(true);
	const [clearSelectedRows, setClearSelectedRows] = useState(false);
	const [editingSchool, setEditingSchool] = useState(null);
	const [editingStudents, setEditingStudents] = useState(null);
	const [addStudentSchool, setAddStudentSchool] = useState(null);
	const [selectedFilter, setSelectedFilter] = useState('');
	const [selectedRows, setSelectedRow] = useState([]);
	const schools = useSelector(state => state.school.schools);

	const newStudentData = {
		student_id: '-1',
		class_id: -1,
		student_name: '',
		parent1_name: '',
		parent1_phone: '',
		parent2_name: '',
		parent2_phone: ''
	};

	const navigate = useNavigate();

	const columns = [
		{
			id: 'school_id',
			name: 'קוד',
			selector: row => row.school_id,
			cell: row => (
				<div>
					<div>{row.school_id}</div>
					<a rel="noreferrer" href={`https://school.mypass.co.il/index.html?sid=${row.school_id}`} target="_blank">פתח קישור</a>
				</div>
			),
			width: '180px'
		}, {
			id: 'school_name',
			name: 'שם',
			sortable: true,
			selector: row => row.school_name,
			cell: row => <div>{row.school_name}</div>,
			width: '200px'
		}, {
			id: 'is_locked',
			name: 'מצב',
			sortable: true,
			selector: row => row.is_locked,
			cell: row => <div>{row.is_locked ? 'נעול לשינויים' : 'פתוח לשינויים'}</div>,
			width: '150px'
		}, {
			id: 'guard_pass',
			name: 'סיסמת שומר',
			sortable: true,
			selector: row => row.guard_pass,
			cell: row => (
				<div>
					<PassField>{row.guard_pass}</PassField>
					<a rel="noreferrer" href={`https://school.mypass.co.il/guard.html?sid=${row.school_id}`} target="_blank">פתח קישור</a>
				</div>
			),
			width: '200px'
		}, {
			id: 'admin_pass',
			name: 'סיסמת מזכירות',
			sortable: true,
			selector: row => row.admin_pass,
			cell: row => (
					<div>
						<PassField>{row.admin_pass}</PassField>
						<a rel="noreferrer" href={`https://school.mypass.co.il/admin.html?sid=${row.school_id}`} target="_blank">פתח קישור</a>
					</div>
				),
			width: '200px'
		}, {
			id: 'Logo Path',
			name: 'לוגו',
			selector: row => row.logo_path,
			cell: row => row.logo_path ? <img src={row.logo_path} alt="" style={{maxWidth: '200px', maxHeight: '40px'}} /> : 'N/A'
		}
	];

	const dispatch = useDispatch();

	useEffect(() => {
		const Load = async () => {
			setLoading(true);
			await dispatch(schoolActions.getSchools());
			setLoading(false);
		};
		Load();
	}, [dispatch]);

	if(loading) {
		return (
			<PageBase title="בתי ספר" />
		);
	}

	const closePopup = () => {
		setLoading(true);
		setEditingSchool(null);
		setEditingStudents(null);
		setAddStudentSchool(null);
		doClearSelectedRows();
	};

	const doClearSelectedRows = () => {
		setSelectedRow([]);
		setClearSelectedRows(true);
		setSelectedFilter('');
		setTimeout(() => {
			setClearSelectedRows(false);
			setLoading(false);
		}, 10);
	};

	const onAddSchool = () => {
	};

	const onToggleLock = async () => {
		doClearSelectedRows();
		await dispatch(schoolActions.toggleLockSchool(selectedRows[0].school_id));
	};

	const onEditSchool = () => {
		if(selectedRows[0].is_locked) {
			Toaster.showWarn("בית הספר נעול לעריכה.");
			doClearSelectedRows();
		}
		else {
			setEditingSchool(selectedRows[0]);
		}
	};

	const onAfterUpdateSchool = async () => {
		setEditingSchool(null);
		await dispatch(schoolActions.getSchools());
		Toaster.showSuccess("הפרטים נשמרו בהצלחה");
	};

	const onAfterUpdateStudent = async () => {
		setEditingStudents(null);
		Toaster.showSuccess("פרטי התלמיד נשמרו בהצלחה");
	};

	const onAfterAddStudent = async () => {
		setAddStudentSchool(null);
		Toaster.showSuccess("התלמיד התווסף בהצלחה");
	};

	const onShowTeachers = () => {
		dispatch(schoolActions.setSelectedSchool(selectedRows[0]));
		navigate(TEACHERS);
	};

	const onShowStudents = () => {
		setEditingStudents(selectedRows[0]);
	};

	const onAddStudent = () => {
		if(selectedRows[0].is_locked) {
			Toaster.showWarn("בית הספר נעול לעריכה.");
			doClearSelectedRows();
		}
		else {
			setAddStudentSchool(selectedRows[0]);
		}
	};

	const onChangeFilters = filterBy => {
		setSelectedFilter(filterBy);
	};

	const filteredSchools = schools.filter(s => s.school_id.indexOf(selectedFilter) > -1 || s.school_name.indexOf(selectedFilter) > -1);

	return (
		<PageBase title="בתי ספר">
			<PopupWin
				visible={editingSchool != null}
				onClose={() => closePopup()}
				title="עריכת פרטי בית הספר"
				popupStyle={{width: '600px'}}>
				<EditSchool school={editingSchool} onAfterUpdate={onAfterUpdateSchool} onClose={() => setEditingSchool(null)} />
			</PopupWin>

			<PopupWin
				visible={editingStudents != null}
				onClose={() => closePopup()}
				title="עריכת פרטי תלמידים"
				popupStyle={{width: '600px'}}>
				<EditStudents school={editingStudents} onAfterUpdate={onAfterUpdateStudent} onClose={() => setEditingStudents(null)} />
			</PopupWin>

			<PopupWin
				visible={addStudentSchool != null}
				onClose={() => closePopup()}
				title="הוספת תלמיד"
				popupStyle={{width: '600px'}}>
				<EditStudents studentData={newStudentData} school={addStudentSchool} onAfterUpdate={onAfterAddStudent} onClose={() => setAddStudentSchool(null)} />
			</PopupWin>

			<CustomDataTable
				uid="schools"
				columns={columns}
				data={filteredSchools || []}
				selectableRows
				clearSelectedRows={clearSelectedRows}
				onSelectedRowsChange={state => setSelectedRow(state.selectedRows)}
				headerComponent={<FilterRow
					selectedRows={selectedRows}
					onAddSchool={onAddSchool}
					onEditSchool={onEditSchool}
					onToggleLock={onToggleLock}
					onShowTeachers={onShowTeachers}
					onShowStudents={onShowStudents}
					onAddStudent={onAddStudent}
					onFilter={(filterBy) => onChangeFilters(filterBy)}/>
				}
			/>
		</PageBase>
	)
};

export default Schools;
