import React from 'react';
import './filtersRow.scss';
import {isNullOrEmptyArray} from "../../utils/general";

const FiltersRow = props => (
	<div className={props.seperateButtonsRow ? "filterContainerColumn" : "filterContainerRow"}>
		{
			props.fields &&
			<div className="filterFields">
				{
					props.fields && props.fields.map((field, i) =>
						<div key={i} className="filterField" style={{paddingLeft: '10px'}}>
							{
								i === props.fields.length - 1 ?
									<div style={{display: 'flex', alignItems: 'center', width: '350px'}}>
										{ field.component }
									</div>
									:
									field.component
							}
						</div>

					)
				}
			</div>
		}

		{
			props.buttons &&
			<div className={props.seperateButtonsRow ? "buttonRowStyleSeperate" : "buttonRowStyle"} style={{paddingTop: isNullOrEmptyArray(props.fields) ? '20px' : '0'}}>
				{
					props.buttons.map((btn, i) =>
						<div key={i}>
							{ btn }
						</div>
					)
				}
			</div>
		}
	</div>
);

export default FiltersRow;
