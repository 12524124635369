import {combineReducers} from 'redux';

import schoolReducer from './School/reducer';
import userReducer from './User/reducer';

// Combine all the reducers
export default combineReducers({
	school: schoolReducer,
	user: userReducer
});
