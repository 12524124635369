import axios from 'axios';
import constants from "../../utils/constants";
import {isNullOrEmptyArray} from "../../utils/general";

const log = require("loglevel").getLogger("UserService");
log.setLevel("error");

axios.interceptors.request.use(request => {
	log.info('Starting Request', request.url);
	return request;
});

axios.interceptors.response.use(response => {
	// console.log('Response:', response)
	return response;
});

export const signIn = async (userData, idToken) => {
	log.debug("UserService -> signIn -> Enter. User = ", JSON.stringify(userData));

	return axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/login`, {idToken})
		.then(async response => {
			const res = response.data.user;
			log.debug("UserService -> signIn service -> response: " + JSON.stringify(res));
			return res;
		})
		.catch(response => {
			log.debug("userService -> signIn -> error: ");
			log.debug(response);
			return null;
		})
};

export const signOut = async () => {
	log.debug("UserService -> signOut service -> Enter");
	return true;
};

export const uploadInvoice = async (path) => {
	log.debug("UserService -> uploadInvoice -> Enter");
	const data = {
		path
	};

	return axios.post(`${constants.BACKEND_URL}/invoice/new`, data)
		.then(response => {
			log.debug("UserService -> uploadInvoice -> Done. invoice_id ", response.data.invoice_id);
			return { status: "ok", data: response.data.invoice_id };
		})
		.catch(error => {
			log.debug("userService -> uploadInvoice -> error: ");
			log.debug(error);

			try {
				return { status: "error", data: error.response.data.error };
			}
			catch(e) {
				console.log("userService -> uploadInvoice -> failed to parse error.");
				return null;
			}
		})
};

export const uploadInvoices = async path => {
	log.debug("UserService -> uploadInvoices -> Enter");
	const data = {
		path
	};

	return axios.post(`${constants.BACKEND_URL}/invoice/new`, data)
		.then(response => {
			log.debug("UserService -> uploadInvoices -> Done");
			return { status: "ok", data: response.data.result };
		})
		.catch(error => {
			log.debug("userService -> uploadInvoices -> error: ");
			log.debug(error);

			try {
				return { status: "error", data: error.response.data.error };
			}
			catch(e) {
				console.log("userService -> uploadInvoices -> failed to parse error.");
				return null;
			}
		})
};

export const markInvoicePaid = invoice_id => {
	log.debug("userService -> markInvoicePaid -> Enter");

	return axios.post(`${constants.BACKEND_URL}/invoice/paid`, { invoice_id })
		.then(async response => {
			const res = response.data;
			log.debug("userService -> markInvoicePaid -> response: " + JSON.stringify(res));
			return res;
		})
		.catch(response => {
			log.debug("userService -> markInvoicePaid -> error: ");
			log.debug(response);
		})
};

export const deleteInvoice = (invoice_id, client_id) => {
	log.debug("userService -> deleteInvoice -> Enter");

	return axios.post(`${constants.BACKEND_URL}/invoice/delete`, { invoice_id })
		.then(async response => {
			const res = response.data;
			log.debug("userService -> deleteInvoice -> response: " + JSON.stringify(res));
			return res;
		})
		.catch(response => {
			log.debug("userService -> deleteInvoice -> error: ");
			log.debug(response);
		})
};

export const loadPhotos = (guiFilters, sortData, requestedPageNumber) => {
	const body = {
		filter: {
			...guiFilters,
		},
		pagination: {
			page: requestedPageNumber,
			page_size: constants.DEFAULT_CLIENT_PHOTO_LIST_PAGE_SIZE
		},
		selector: 'full'
	};

	if(sortData) {
		body.order = [sortData];
	}

	log.debug(`PortalService -> loadPortalUsers -> loading with filter: ${JSON.stringify(body)}`);

	return axios.post(`${constants.BACKEND_URL}/client/photo/get`, body )
		.then(async response => {
			return response.data;
		})
		.catch(response => {
			log.debug("userService -> loadPhotos -> error: ");
			log.debug(response);
		})
};

export const loadClientInvoices = client_id => {
	log.debug("userService -> loadClientInvoices -> Enter");

	const body = {
		filter: {
			client_id: [client_id]
		},
		order: [{
			name: 'uploaded_at',
			desc: true
		}]
	};

	return axios.post(`${constants.BACKEND_URL}/invoice/get`, body )
		.then(async response => {
			const res = response.data.invoices;
			log.debug("userService -> loadClientInvoices -> response: " + JSON.stringify(res));
			return res;
		})
		.catch(response => {
			log.debug("userService -> loadClientInvoices -> error: ");
			log.debug(response);
		})
};

export const loadClientById = async client_id => {
	const body = {
		filter: {
			client_id: [client_id]
		}
	};

	log.debug(`userService -> loadClientById -> loading with filter: ${JSON.stringify(body)}`);

	return axios.post(`${constants.BACKEND_URL}/client/get`, body)
		.then(async response => {
			log.debug(`userService -> loadClientById -> server returned ${response.data.clients.length} rows`);

			return response.data.clients[0];
		})
		.catch(response => {
			log.debug("userService -> loadClientById -> Error: ");
			log.debug(response);
		})
};

export const loadInvoiceById = async invoice_id => {
	log.debug("UserService -> loadInvoiceById -> Enter");

	const body = {
		filter: {
			invoice_id: [invoice_id]
		}
	};

	return axios.post(`${constants.BACKEND_URL}/invoice/get`, body)
		.then(async response => {
			const res = isNullOrEmptyArray(response.data.invoices) ? null : response.data.invoices[0];
			log.debug("userService -> loadInvoiceById -> response: " + JSON.stringify(res));
			return res;
		})
};

export const updateInvoice = invoice => {
	log.debug("userService -> updateInvoice -> Enter");

	console.log("userService -> updateInvoice -> invoice ", invoice);

	return axios.post(`${constants.BACKEND_URL}/invoice/save`, invoice )
		.then(async response => {
			return true;
		})
		.catch(response => {
			log.debug("userService -> updateInvoice -> error: ");
			log.debug(response);
		})
};

export const approvePhoto = (photo_id, updated_title) => {
	log.debug("userService -> approvePhoto -> Enter");

	return axios.post(`${constants.BACKEND_URL}/client/photo/approve`, { photo_id, updated_title } )
		.then(async response => {
			return true;
		})
		.catch(response => {
			log.debug("userService -> approvePhoto -> error: ");
			log.debug(response);
		})
};

export const declinePhoto = (photo_id, reason) => {
	log.debug("userService -> declinePhoto -> Enter");

	return axios.post(`${constants.BACKEND_URL}/client/photo/decline`, { photo_id, reason } )
		.then(async response => {
			return true;
		})
		.catch(response => {
			log.debug("userService -> declinePhoto -> error: ");
			log.debug(response);
		})
};

export const sendEmailToClients = (source, templateId, clientIds) => {
	log.debug("PortalService -> sendEmailToClients -> Enter");

	const data = {
		source,
		template: templateId,
		clients: clientIds
	};

	console.log(data);

	return axios.post(`${constants.BACKEND_URL}/template/email/send`, data)
		.then(async response => {
			log.debug(`PortalService -> sendEmailToClients -> server returned ${response.data.portal_users.length} rows`);

			return response.data.portal_users[0];
		})
		.catch(response => {
			log.debug("PortalService -> sendEmailToClients -> Error: ");
			log.debug(response);
		})
};

export const uploadGalleryPhoto = (client_id, url, title) => {
	log.debug("userService -> updateInvoice -> Enter");
	const data = {
		client_id,
		url,
		title
	};

	return axios.post(`${constants.BACKEND_URL}/client/photo/new`, data )
		.then(async response => {
			log.debug("userService -> updateInvoice -> done. response = ", response);
			return true;
		})
		.catch(response => {
			log.debug("userService -> updateInvoice -> error: ");
			log.debug(response);
			return false;
		})
};
