import {toast} from 'react-toastify';

const duration = 3500;

const log = require("loglevel").getLogger("Toaster");
log.setLevel("debug");

export default class Toaster {
	static showSuccess(message) {
		toast.success(message, {
			autoClose: duration
		});
	}
	static showWarn(message) {
		toast.warn(message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true
		});
	}
	static showError(message) {
		toast.error(message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true
		});
	}
}
