import React, {Component} from 'react';
import Icon from 'react-web-vector-icons';
import Popup from "reactjs-popup";
import './popupWin.scss';
import colors from "../../utils/colors";
import constants from "../../utils/constants";
import {isNullOrFalse} from "../../utils/general";

const log = require("loglevel").getLogger("Popup");
log.setLevel("error");

class PopupWin extends Component {
	render() {
		const { visible, contentStyle, hideClose, onClose, closeOnEscape, closeOnDocumentClick, popupStyle, children, title, subtitle } = this.props;

		return (
			<Popup
				closeOnEscape={closeOnEscape}
				closeOnDocumentClick={closeOnDocumentClick}
				open={visible}
				onClose={onClose}
				modal
				nested
				contentStyle={Object.assign(popupStyle || {}, {overflow: 'auto', padding: 0, border: 0, backgroundColor: 'transparent'}) }>
				<div className="popupContainer">

					<div className="titleRow" style={{height: `${constants.POPUP_TITLE_HEIGHT}px`}}>
						{
							isNullOrFalse(hideClose) &&
							<div onClick={onClose} style={{padding: '15px', cursor: 'pointer'}}>
								<Icon name="close" font="AntDesign" color={colors.glowingGreen}/>
							</div>
						}
						{ title && <div className="title">{title} <div className="subtitle">{subtitle}</div></div> }
					</div>

					<div className="contentContainer" style={contentStyle || {width: '100%'} }>
						{ children }
					</div>

				</div>
			</Popup>
		)
	}
}

export default PopupWin;
