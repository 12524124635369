import React, {Component, createRef} from 'react';
import DataTable from 'react-data-table-component';
import Icon from 'react-web-vector-icons';
import './customDataTable.scss';
import {isNullOrEmptyArray} from "../../utils/general";
import colors from "../../utils/colors";

const LOAD_MORE_REMAINING_SCROLL = 200;

class CustomDataTable extends Component {

	tableRef = createRef();

	customStyles = {
		table: {
			style: {
				direction: 'rtl',
				backgroundColor: colors.transparent
			}
		},
		subHeader: {
			style: {
				minHeight: '0',
			},
		},
		head: {
			style: {
				minHeight: '0',
				padding: '0'
			},
		},
		header: {
			style: {
				minHeight: '0',
			}
		},
		headRow: {
			style: {
				backgroundColor: '#ddf3f6',
			}
		},
		noData: {
			style: {
			},
		},
		rows: {
			style: {
				minHeight: 'auto', // override the row height
				backgroundColor: colors.transparent,
				borderBottom: '1px solid #B9BEC0 !important',
				color: colors.lightText,
				fontSize: '14px',
				padding: '13px 0',
				alignItems: 'flex-start'
			}
		},
		cells: {
			style: {
				paddingLeft: '0px', // override the cell padding for data cells
				paddingRight: '0px',
			},
		},
		expanderRow: {
			style: {
				backgroundColor: '#0c424b',
			},
		},
	};

	componentDidMount() {
		try {
			const node = this.tableRef.current;
			node.querySelector(".rdt_TableBody").removeEventListener('scroll', this.tableScroll);
			node.querySelector(".rdt_TableBody").addEventListener('scroll', this.tableScroll);
		}
		catch (e) {}
	}

	componentDidUpdate(prevProps) {
		if(this.props.uid === prevProps.uid && !isNullOrEmptyArray(this.props.data) && prevProps.data != null && this.props.data.length !== prevProps.data.length) {
			try {
				const node = this.tableRef.current;
				node.querySelector(".rdt_TableBody").removeEventListener('scroll', this.tableScroll);
				node.querySelector(".rdt_TableBody").addEventListener('scroll', this.tableScroll);

				// if(JSON.stringify(this.props.selectedFilters) !== JSON.stringify(prevProps.selectedFilters)) {
				// 	node.querySelector(".rdt_TableBody").scrollTo(0, 0);
				// }

			}
			catch (e) {}
		}
	}

	componentWillUnmount() {
		try {
			const node = this.tableRef.current;
			node.querySelector(".rdt_TableBody").removeEventListener('scroll', this.tableScroll);
		}
		catch (e) {}
	}

	tableScroll = event => {
		try {
			const node = this.tableRef.current;
			const el = node.querySelector(".rdt_TableBody");
			const remainingScrollHeight = el.scrollHeight - (el.scrollTop + el.clientHeight);

			if (remainingScrollHeight < LOAD_MORE_REMAINING_SCROLL) {
				if(this.props.onScrollPassedLoadingPoint) {
					this.props.onScrollPassedLoadingPoint();
				}
			}
		}
		catch (e) {}
	};

	onSort = (column, dir) => {
		try {
			const node = this.tableRef.current;
			node.querySelector(".rdt_TableBody").scrollTo(0,0);
		}
		catch (e) {}
		this.props.onSort(column, dir);
	};

	renderTabs(tabs) {
		return (
			<div className="tabs-container">
				{ tabs.map((tab, i) => <div key={i} onClick={() => this.props.onChangeTab(tab.id)} className={`tabs-tab${tab.selected ? '-selected' : ''}`}>{tab.text} <div className="tabs-tab-amount">{tab.amount}</div></div>) }
			</div>
		)
	}

	renderPaging(paging) {
		const { data, onChangePage } = this.props;

		const pages = isNullOrEmptyArray(data) ? 0 : Math.ceil(paging.count / paging.size);

		return (
			<div className="grid-paging">
				{
					paging.page > 1 ?
						<div className="grid-paging-item" onClick={() => onChangePage(1)}><Icon name='chevrons-left' font='Feather' color={colors.link} size={24} /></div>
						:
						<div className="grid-paging-item-disabled"><Icon name='chevrons-left' font='Feather' color={colors.gray} size={24} /></div>
				}

				{
					paging.page > 1 ?
						<div className="grid-paging-item" onClick={() => onChangePage(paging.page - 1)}><Icon name='chevron-left' font='Feather' color={colors.link} size={24} /></div>
						:
						<div className="grid-paging-item-disabled"><Icon name='chevron-left' font='Feather' color={colors.gray} size={24} /></div>
				}

				{
					pages === 0 ?
						<div className="grid-paging-item-disabled">No Records</div>
						:
						<select className="grid-paging-select"
						        value={paging.page}
						        onChange={(obj) => onChangePage(parseInt(obj.currentTarget.value))}>
							{ Array(pages).fill(0).map((a, i) => (<option key={i} value={i+1}>Page {i+1} of {pages}</option>) ) }
						</select>
				}

				{
					paging.page < pages ?
						<div className="grid-paging-item" onClick={() => onChangePage(paging.page + 1)}><Icon name='chevron-right' font='Feather' color={colors.link} size={24} /></div>
						:
						<div className="grid-paging-item-disabled"><Icon name='chevron-right' font='Feather' color={colors.gray} size={24} /></div>
				}

				{
					paging.page < pages ?
						<div className="grid-paging-item" onClick={() => onChangePage(pages)}><Icon name='chevrons-right' font='Feather' color={colors.link} size={24} /></div>
						:
						<div className="grid-paging-item-disabled"><Icon name='chevrons-right' font='Feather' color={colors.gray} size={24} /></div>
				}
			</div>
		)
	}

	render() {
		const {
			columns,
			data,
			tabs,
			paging,
			inPopup,
			loadingMore,
			selectableRows,
			highlightOnHover,
			clearSelectedRows,
			defaultSortAsc,
			defaultSortFieldId,
			onSelectedRowsChange,
			fixedHeaderScrollHeight,
			headerComponent,
			expandableRows,
			expandableRowsComponent
		} = this.props;

		return (
			<div ref={this.tableRef} className={this.props.uid}>

				<div className="data-drid-header-component">
					{ headerComponent }
				</div>
				<div className="gridHeader">
					{ tabs ? this.renderTabs(tabs) : <div/> }
					{ paging && this.renderPaging(paging) }
				</div>
				{
					data.length === 0 ?
						<div className="data-grid-no-results">No Results</div>
						:
						<div style={{ backgroundColor: loadingMore ? '#f1f1f1' : 'transparent' }}>
							<DataTable
								columns={columns}
								defaultSortAsc={defaultSortAsc}
								defaultSortFieldId={defaultSortFieldId}
								data={data}
								selectableRowsHighlight
								selectableRows={selectableRows}
								onSelectedRowsChange={onSelectedRowsChange}
								onSort={this.onSort}
								customStyles={this.customStyles}
								expandableRows={!!expandableRows}
								highlightOnHover={highlightOnHover}
								clearSelectedRows={clearSelectedRows}
								fixedHeaderScrollHeight={fixedHeaderScrollHeight || (inPopup ? 'calc(100vh - 440px)' : 'calc(100vh - 310px)')}
								expandableIcon={{ collapsed: <Icon name='chevron-right' font='Entypo' color={colors.link} size={24} />, expanded: <Icon name='chevron-down' font='Entypo' color={colors.link} size={24} /> }}
								expandableRowsComponent={expandableRows ? expandableRowsComponent : <div />}
								subHeader={false}
								fixedHeader
								sortServer
							/>
						</div>
				}
			</div>
		)
	}
}

export default CustomDataTable;
