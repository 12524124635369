import * as actionTypes from './actionTypes';
import constants from "../../utils/constants";

const log = require("loglevel").getLogger("SchoolReducer");
log.setLevel("debug");

const initialState = JSON.stringify({
	schools: [],
	selectedSchool: -1,
	teachers: [],
	classes: [],
	lastUpdated: null,
	availableSms: 0
});

function SchoolReducer(state = JSON.parse(initialState), action) {
	switch (action.type) {
		case constants.GLOBAL_ACTION_INIT_STATE: {
			log.debug("reset admin state");
			return initialState;
		}
		case actionTypes.SCHOOLS_LOADED: {
			return {
				...state,
				schools: action.data
			};
		}
		case actionTypes.TEACHERS_LOADED: {
			return {
				...state,
				teachers: action.data,
				lastUpdated: new Date()
			};
		}
		case actionTypes.CLASSES_LOADED: {
			return {
				...state,
				classes: action.data,
				lastUpdated: new Date()
			};
		}
		case actionTypes.UPDATE_AVAILABLE_SMS: {
			return {
				...state,
				availableSms: action.data
			};
		}
		case actionTypes.SET_SELECTED_SCHOOL: {
			return {
				...state,
				selectedSchool: action.data
			}
		}
		default:
			return state;
	}
}

export default SchoolReducer;
